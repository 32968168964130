import { AppExposedApis } from '@wix/platform-editor-sdk';
import {
  AppManifestBuilder,
  ComponentRef,
  EditorReadyFn,
  FlowEditorSDK,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';
import comp from './components/ExpressCheckoutWidget/.component.json';

const widgetId = comp.id;

function createApp(): {
  editorReady: EditorReadyFn;
  getAppManifest: GetAppManifestFn;
  exports(editorSDK: FlowEditorSDK): AppExposedApis;
} {
  return {
    async editorReady(editorSDK, appDefinitionId) {
      // TODO: remove below (example of use api method)
      // const { applicationId } =
      //   await editorSDK.document.tpa.app.getDataByAppDefId('', appDefinitionId);
      // // NOTE there will be few components if few apps added
      // const { id: componentId } = (
      //   await editorSDK.document.tpa.app.getAllCompsByApplicationId(
      //     '',
      //     applicationId,
      //   )
      // )[0];
      // const compRef = await editorSDK.document.components.getById('', {
      //   id: componentId,
      // });
      // (
      //   await editorSDK.document.application.getPublicAPI('', {
      //     appDefinitionId,
      //   })
      // )
      //   .setProps(
      //     {
      //       currency: 'USD',
      //       domain: 'https://cashier-automation.wixsite.com/paypal',
      //       meta: {
      //         appDefId: '1380b703-ce81-ff05-f115-39571d94dfcd',
      //         appInstance:
      //           'xN4jA8h-M5j5OeLMhJ0yaCUVrFygXRUZg8r2zhbHYu4.eyJpbnN0YW5jZUlkIjoiZTZiMzNjYTgtNWQ5Yy00MThiLTg4NTktMzdjOTY2ZTQ1OTU2IiwiYXBwRGVmSWQiOiIxMzgwYjcwMy1jZTgxLWZmMDUtZjExNS0zOTU3MWQ5NGRmY2QiLCJtZXRhU2l0ZUlkIjoiNTBlOGNhNjctMmQzZS00MjQ0LWE0MjAtOGYyY2Y4ZTk1ZTk1Iiwic2lnbkRhdGUiOiIyMDIxLTEyLTA3VDA4OjAwOjU2LjkzMVoiLCJ2ZW5kb3JQcm9kdWN0SWQiOiJzdG9yZXNfZ29sZCIsImRlbW9Nb2RlIjpmYWxzZSwib3JpZ2luSW5zdGFuY2VJZCI6ImIxZDc5NzhmLWNjZmItNGUxNC1iOGY5LTkwMzhmOTQyOGU1YiIsImFpZCI6ImE2YWMwZmUxLTYxZGEtNGM1NC1iMWU3LTZiNWY4OWMzNWVlNCIsImJpVG9rZW4iOiJiNjViZjZjZi03MGEyLTAzY2YtMmM3OS1iOGU1OWUwZDA3YzMiLCJzaXRlT3duZXJJZCI6IjkxMjkyMjA1LTc4MDEtNDE1NC1hNTk0LWMzNjJhNjQ2ZDEzNCJ9',
      //         appInstanceId: 'e6b33ca8-5d9c-418b-8859-37c966e45956',
      //         siteId: '50e8ca67-2d3e-4244-a420-8f2cf8e95e95',
      //         visitorId: 'a6ac0fe1-61da-4c54-b1e7-6b5f89c35ee4',
      //       },
      //       orderItems: [
      //         {
      //           category: 'physical',
      //           id: '1',
      //           name: 'copy of Cashier PayPal',
      //           price: '0.01',
      //           quantity: 1,
      //         },
      //       ],
      //       paymentAmount: '0.01',
      //       paymentBreakdown: {
      //         discount: '0',
      //         itemsTotal: '0.01',
      //         shipping: '0',
      //         tax: '0',
      //       },
      //       paymentLabel: 'forApplePay',
      //       theme: 'dark',
      //       onPaymentAuthorized: {},
      //     },
      //     compRef,
      //   );
    },
    async getAppManifest({
      appManifestBuilder,
    }: {
      appManifestBuilder: AppManifestBuilder;
    }) {
      return {
        ...appManifestBuilder
          .configureWidget(widgetId, (widgetBuilder) => {
            widgetBuilder.set({
              displayName: 'Express Checkout Widget',
              nickname: 'ewidget',
            });
          })
          .build(),
        exports: {
          [widgetId]: {
            inherits: {},
            members: {
              setProps: {
                kind: 'function',
                description:
                  'Used for set/update cashier-express-checkout-widget props',
              },
            },
          },
        },
      };
    },
    exports(editorSDK: FlowEditorSDK) {
      return {
        public: {
          // should not be neeeded
          setProps: async (widgetProps, compRef: ComponentRef) => {
            await editorSDK.document.tpa.data.set('', {
              compRef,
              key: 'widgetProps',
              scope: 'COMPONENT',
              value: widgetProps,
            });
          },
        },
      };
    },
  };
}

export const { editorReady, getAppManifest, exports } = createApp();
